@keyframes preloader-inside-white {
    0% {
        -webkit-transform: scale(0, 0);
        -moz-transform: scale(0, 0);
        -ms-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        transform: scale(0, 0);
    }
    100% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}

@keyframes preloader-inside-red {
    0% {
        -webkit-transform: scale(0, 0);
        -moz-transform: scale(0, 0);
        -ms-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        transform: scale(0, 0);
    }
    30% {
        -webkit-transform: scale(0, 0);
        -moz-transform: scale(0, 0);
        -ms-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        transform: scale(0, 0);
    }
    100% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}

.preloader {
    width: 100%;
    height: 100%;
    z-index: 100;
    padding: 20px;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    visibility: hidden;
}
.preloader:before{
  position: absolute;
  width: 100%;
  height: 100%;
  content: '';
  background-color: #000000;
  opacity: .7;
}
.preloader_active{
  visibility: visible;
}
.preloader__container {
    width: 100px;
    height: 100px;
    position: relative;
}
.preloader__round {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 0;
    left: 0;
    background: #FF6838;
    border-radius: 50px;
}

.preloader__round::after,
.preloader__round::before {
    content: '';
    width: 100px;
    height: 100px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50px;
}

.preloader__round::after {
    background: white;
    animation: preloader-inside-white 1s ease-in-out infinite;
}

.preloader__round::before {
    z-index: 10;
    background: #FF6838;
    animation: preloader-inside-red 1s ease-in-out infinite;
}
@media screen and (max-width:320px) {
  .preloader{
    max-width: 320px;
  }
}
