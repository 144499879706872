.techs{
  min-height: 625px;
  background: #F5F5F5;
  box-sizing: border-box;
  padding: 100px 70px 100px 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 90px;
  width: 100%;
}
.tech__content{
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0;
  justify-content: flex-start;
  gap: 100px;
}
.tech__text-content{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.techs__title{
  font-size: 50px;
  font-weight: 400;
  line-height: 58px;
  margin: 0 0 26px 0;
  text-align: center;
}
.techs__subtitle{
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  text-align: center;
  max-width: 460px;
}
.technologies{
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  gap: 10px;
}
.technologies__item{
  margin: 0;
  padding: 0;
  list-style-type: none;
  background-color: #E8E8E8;
  min-width: 90px;
  min-height: 60px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
}
.technologies__item:before{
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}
@media screen and (max-width: 920px){
  .technologies{
    max-width: 300px;
    flex-wrap: wrap;
  }
  .tech__content{
    gap: 83px;
  }
}
@media screen and (max-width: 700px){
  .techs{
    padding: 90px 50px 90px 50px;
  }
  .technologies{
    max-width: 300px;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 600px){
  .techs__title{
    font-size: 30px;
    font-weight: 400;
    line-height: 36px;
  }
  .techs__subtitle{
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    min-width: 292px;
  }
}
@media screen and (max-width: 450px){
  .techs{
    padding: 70px 18px 70px 18px;
    gap: 60px;
    background: #FFFFFF;
  }
  .tech__content{
    gap: 50px;
  }
  .technologies{
    max-width: 200px;
  }
}
