.nav-movies{
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.nav__item{
  text-decoration: none;
  height: 32px;
  min-width: 76px;
  border-radius: 3px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #000000;
}
.nav__item-container{
  display: flex;
  margin: 0 0 0 44px;
  width: 100%;
  justify-content: space-between;
}
.nav__item-container__movies-links{
  display: flex;
  gap: 30px;
}
.nav__auth-container{
  display: flex;
  gap: 30px;
}
.nav__link{
  text-decoration: none;
  color: #000000;
  box-sizing: border-box;
}
.nav__item_type_active{
  border-bottom: 2px solid #2BE080;
}
.nav__item_type_signin{
  background-color: #2BE080;
  color: #000000;
}
.nav__item_type_films{
  max-width: 142px;
}
.nav__item_type_saved-films{
  max-width: 142px;
}
.nav__item:before{
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}
.nav__item_type_signup{
  color: #000000;
}
.nav__item_type_account{
  height: 32px;
  width: 100px;
  background: #F9F9F9;
  font-size: 14px;
  font-weight: 500;
  justify-self: flex-end;
}
.nav__item_type_main{
  display: none;
}

@media screen and (max-width: 800px){
  .nav__item-container{
    display: none;
  }
  .nav__item_type_main{
    display: block;
  }
}
@media screen and (max-width: 500px){
  .nav__auth-container{
    gap: 14px;
  }
  .nav__item{
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
  }
  .nav__item_type_signin{
    min-width: 54px;
    max-height: 26px;
  }
  .nav__item_type_signup{
    min-width: 54px;
    max-height: 26px;
  }
}
