.form-profile{
  height: 430px;
  width: 380px;
  display: flex;
  flex-direction: column;
  padding: 60px 40px 40px 40px;
  background: #FFFFFF;
  box-sizing: border-box;
  border-radius: 15px;
}
.form-profile__field{
  border: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
}
.form-profile__label{
  display: flex;
  flex-direction: column;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  color: #A0A0A0;
  margin: 0 0 6px 0;
  min-height: 32px;
  justify-content: flex-end;
}
.form-profile__input{
  margin: 0;
  padding: 0;
  border:none;
}
.form-profile__input_type_login{
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  margin: 5px 0 0 0;
  border-bottom: 1px solid #E8E8E8;
  padding: 0 0 10px 0;
}
.form-profile__input_type_error{
  color: #FF3055;
  border-bottom: 1px solid #FF3055;
}
.form-profile__error-span{
  color: #FF3055;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  padding: 6px 0 0 0;
  visibility: hidden;
}
.form-profile__error-span_active{
  visibility: visible;
}
.form-profile__submit{
  background-color: #3456F3;
  color: #FFFFFF;
  min-height: 45px;
}
.form-profile__submit_disabled{
  cursor: default;
  background: #F8F8F8;
  color: #C2C2C2;
  border: none;
}

@media screen and (max-width: 500px){
  .form-profile{
    padding: 50px 15px;
    max-width: 260px;
    max-height: 380px;
  }
}
