.form-login{
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 40px 0 0 0;
  flex: 1 0 auto;
}
.form-login__field{
  border: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
}
.form-login__label{
  display: flex;
  flex-direction: column;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  color: #A0A0A0;
  margin: 0 0 6px 0;
  min-height: 32px;
  justify-content: flex-end;
}
.form-login__input{
  margin: 0;
  padding: 0;
  border:none;
}
.form-login__input_type_login{
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  margin: 5px 0 0 0;
  border-bottom: 1px solid #E8E8E8;
  padding: 0 0 10px 0;
}
.form-login__input_type_error{
  color: #FF3055;
  border-bottom: 1px solid #FF3055;
}
.form-login__error-span{
  color: #FF3055;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  padding: 6px 0 0 0;
  visibility: hidden;
}
.form-login__error-span_active{
  visibility: visible;
}
.form-login__submit{
  background-color: #3456F3;
  color: #FFFFFF;
  min-height: 45px;
}
.form-login__submit_disabled{
  cursor: default;
  background: #F8F8F8;
  color: #C2C2C2;
  border: none;
}

@media screen and (max-width: 500px){
  .form-login{
    padding: 80px 0 0 0;
    max-width: 260px;
  }
}
