.search-container{
  margin: 70px 0 70px 0;
  width: calc(100% - 70px *2);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  background: #F9F9F9;
  border-radius: 9px;
}
.movies__content{
  display: flex;
  flex-direction: column;
  width: calc(100% - 70px *2);
  align-items: center;
}
.movies__container{
  display: flex;
  flex: 1 0 auto;
}

.movies__no-result{
  color: #A0A0A0;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
}
.more-button-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0 80px 0;
  width: 100%;
  max-width: calc(100% - 70px *2);
}
.load-more-btn{
  padding: 0;
  height: 36px;
  width: 320px;
  background: none;
  border: 1px solid #E8E8E8;
  cursor: pointer;
  border-radius: 5px;
}
@media screen and (max-width:1200px) {
  .search-container{
    width: calc(100% - 30px *2);
  }
}
@media screen and (max-width:650px) {
  .search-container{
    flex-direction: column;
    max-width: calc(100% - 10px *2);
    align-items: center;
    box-sizing: border-box;
  }
  .more-button-container{
    max-width: calc(100% - 40px * 2);
  }
  .load-more-btn{
    max-width: 240px;
  }
}
