.auth-navigation{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 0 0 0;
}
.auth-navigation__text{
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: #A0A0A0;
  margin: 0;
  display: flex;
  gap: 6px;
}
.auth-navigation__link{
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: #3456F3;
  text-decoration: none;
}
@media screen and (max-width: 500px){
  .auth-navigation{
    max-width: 260px;
  }
  .auth-navigation__text{
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
  }
  .auth-navigation__link{
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
  }
}
