.movies-card-list{
  padding: 80px 0 0 0;
  display: grid;
  grid-template-columns: repeat(3, max-content);
  column-gap: 24px;
  row-gap: 30px;
  justify-content: center;
  border-top: 1px solid #E8E8E8;
  max-width: calc(100% - 70px * 2);
  box-sizing: border-box;
  width: 100%;
}
@media screen and (max-width:1200px) {
  .movies-card-list{
    grid-template-columns: repeat(2, max-content);
    grid-auto-rows: 1fr;
    column-gap: 17px;
    row-gap: 20px;
    justify-content: center;
    max-width: calc(100% - 30px * 2);
    width: 100%;
  }
}
@media screen and (max-width:1000px) {
  .movies-card-list{
    column-gap: 30px;
    row-gap: 36px;
  }
}
@media screen and (max-width:760px) {
  .movies-card-list{
    grid-template-columns: minmax(min-content, max-content);
    grid-template-rows: 1fr;
    justify-content: center;
  }
}
@media screen and (max-width:360px) {
  .movies-card-list{
    max-width: 300px;
    box-sizing: border-box;
    column-gap: 0;
  }
}
