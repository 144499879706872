.movie-card{
  display: flex;
  flex-direction: column;
  max-height: 271px;
  max-width: 364px;
  min-width: 300px;
  background: #FDFDFD;
  box-shadow: 1px 4px 30px rgba(0, 0, 0, 0.08);
}
.movie-card__description{
  padding: 14px;
  display: flex;
  justify-content: space-between;
}
.movie-card__info{
  display: flex;
  flex-direction: column;
  gap: 9px;
}
.movie-card__title{
  max-width: 280px;
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  color: #000000;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.movie-card__duration{
  margin: 0;
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
  color: #A0A0A0;
}
.movie-card__image{
  max-width: 100%;
  max-height: 203px;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.movie-card__link{
  width: 100%;
  height: 100%;
}

.movie-card__save-button{
  height: 30px;
  width: 30px;
  border: none;
  cursor: pointer;
  background: url("../../images/save-movie-disabled.svg") center;
}

.movie-card__save-button_active{
  height: 30px;
  width: 30px;
  border: none;
  cursor: pointer;
  background: url("../../images/save-movie-active.svg") center;
}

.movie-card__delete-button{
  height: 30px;
  width: 30px;
  border: none;
  cursor: pointer;
  background: url("../../images/delete-movie.svg") center;
}

@media screen and (max-width: 1000px) {
  .movie-card{
    max-width: 339px;
  }
}
@media screen and (max-width: 400px) {
  .movie-card{
    max-width: 300px;
    box-sizing: border-box;
  }
  .movie-card__title{
    max-width: 200px;
  }
}
