.promo{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  margin: 0 auto;
  height: 493px;
  width: 100%;
  left: 40px;
  top: 94px;
  border-radius: 10px;
  background-color: #F5F5F5;
  flex: 1 0 auto;
  box-sizing: border-box;
}
.promo__heading{
  font-size: 50px;
  font-weight: 400;
  line-height: 58px;
  letter-spacing: -0.04em;
  text-align: center;
  max-width: 730px;
  margin: 0;
  flex: 0.5 0 auto;
}
.promo__nav-container{
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 0 0 auto;
  margin: 0 0 30px 0;
}
.promo__nav-item{
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  height: 36px;
  min-width: 96px;
  border-radius: 10px;
  color: #FFFFFF;
  text-align: center;
  cursor: pointer;
  background-color: #2be080;
  transition: background-color ease .5s;
}
.promo__nav-item:hover {
  background-color: #E8E8E8;
  transition: background-color ease .5s;
  opacity: 1;
}
.promo__nav-item:before{
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

@media screen and (max-width: 800px){
  .promo__heading{
    font-size: 40px;
    font-weight: 400;
    line-height: 52px;
    max-width: 680px;
  }
}
@media screen and (max-width: 600px){

  .promo__heading{
    font-size: 29px;
    font-weight: 400;
    line-height: 37px;
    max-width: 480px;
  }
  .promo__nav-container{
    gap: 6px;
  }
  .promo__nav-item{
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
    min-width: 82px;
  }
}
@media screen and (max-width: 450px){
  .promo__heading{
    max-width: 292px;
  }
}
