.popup{
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 2.3s linear;
}
.popup:before{
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
}
.popup_opened{
  visibility: visible;
  z-index: 10;
  opacity: 1;
  transition: visibility 1s, opacity .3s linear;
}
.popup__content{
  z-index: 10;
  position: relative;
  display: flex;
}
.popup__close{
  position: absolute;
  top: 15px;
  right: 15px;
  background: url("../../images/close-nav-popup.svg") center;
  z-index: 10;
  width: 32px;
  height: 32px;
  border: none;
  cursor: pointer;
}
