.search-form{
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding: 19px 30px 19px 19px;
  background-color: #F9F9F9;
  border-radius: 9px;
}
.search-form__fieldset {
  border: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex: 1 0 auto;
  gap: 10px;
}
.search-form__input{
  margin: 0;
  border:none;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  background-color: #F9F9F9;
  color: #A0A0A0;
  padding: 0 0 0 10px;
  flex: 1 0 auto;
}
.search-form__submit{
  padding: 0;
  border: none;
  cursor: pointer;
  background-image: url("../../images/submit-btn-search-form.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-color: #3456F3;
  height: 34px;
  width: 34px;
  border-radius: 48px;
  margin: 0;
}
.search-form__submit_disabled{
  cursor: default;
  background-color: #C2C2C2;
  color: #C2C2C2;
  border: none;
}
.search-icon{
  max-height: 32px;
  max-width: 32px;
}
@media screen and (max-width:650px) {
  .search-form{
    flex-direction: column;
    border-radius: 9px;
    max-width: 100%;
  }
  .search-icon{
    display: none;
  }
  .search-form__fieldset{
    border: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex: 1 0 auto;
    width: 100%;
    border-radius: 9px;
  }
  .search-form__submit{
    margin: 0;
  }
}
@media screen and (max-width:500px) {
  .search-form__input{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}
