.auth-heading__heading{
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  margin: 40px 0 0 0;
}

@media screen and (max-width: 500px){
  .auth-heading{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .auth-heading__heading{
    max-width: 260px;
    margin: 50px 0 0 0;
  }
}
