.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 74px;
  width: calc(100% - 70px *2);
  margin: 0 auto;
  box-sizing: border-box;
}
.header__logo{
  width: 38px;
  height: 38px;
  margin: 0;
}
.header__burger-button{
  display: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  border: none;
  height: 44px;
  width: 44px;
  background: url("../../images/header-burger-button.svg") center;
}
@media screen and (max-width: 1200px){
  .header{
    width: calc(100% - 30px *2);
  }
}
@media screen and (max-width: 800px){
  .header__burger-button{
    display: block;
  }
}
@media screen and (max-width: 500px){
  .header{
    width: calc(100% - 14px *2);
  }
}
