.portfolio{
  min-height: 425px;
  padding: 0 70px 125px 70px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 100%;
}
.portfolio__heading{
  color: #A0A0A0;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
}
.portfolio-links{
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 auto;
}
.portfolio-links__item{
  min-width: 100%;
  border-bottom: 1px solid #E8E8E8;
  display: flex;
  justify-content: space-between;
}

.portfolio-links__text{
  font-size: 30px;
  font-weight: 400;
  line-height: 50px;
  margin: 0;
  padding: 0 0 20px 0;
  display: flex;
}
.portfolio-links__item:last-of-type{
  border: none;
}
.portfolio-links__arrow{
  font-size: 30px;
  font-weight: 400;
  line-height: 60px;
}
.portfolio-links__link{
  text-decoration: none;
  color: #000000;
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: 400;
  line-height: 50px;
  background: #2be080;
  align-self: flex-start;
  border-radius: 5px;
  box-sizing: border-box;
  min-width: 140px;
  align-items: center;
  max-height: 50px;
}
.portfolio-links__container {
  display: flex;
  gap: 10px;
  align-self: center;
}
@media screen and (max-width: 770px){
  .portfolio {
    padding: 0 50px 90px 50px;
  }
  .portfolio__heading{
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
  }
  .portfolio-links__text{
    font-size: 28px;
    font-weight: 400;
    line-height: 50px;
  }
  .portfolio-links__link{
    font-size: 18px;
    font-weight: 400;
    line-height: 50px;
    min-width: 100px;
  }
  .portfolio-links__arrow{
    font-size: 28px;
    font-weight: 400;
    line-height: 60px;
  }
}
@media screen and (max-width: 770px){
  .portfolio {
    padding: 0 14px 70px 14px;
  }
  .portfolio__heading{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  .portfolio-links__text{
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
  }
  .portfolio-links__link{
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
  }
  .portfolio-links__arrow{
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
  }
}

@media screen and (max-width: 500px){
  .portfolio__heading{
    font-size: 10px;
    font-weight: 400;
    line-height: 18px;
  }
  .portfolio-links__text{
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
  }
  .portfolio-links__link{
    font-size: 12px;
    font-weight: 400;
    line-height: 28px;
    min-width: 70px;
  }
  .portfolio-links__arrow{
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
  }
}
