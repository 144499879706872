.not-found{
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  flex: 0 0 auto;
}
.not-found__content{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  flex: 0.5 0 auto;
}
.not-found__heading{
  padding: 0;
  line-height: 169.43px;
  font-size: 140px;
  text-align: center;
  width: 400px;
  margin: 0 0 5px 0;
}
.not-found__subheading{
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 19.36px;
}
.not-found__go-back{
  justify-self: flex-end;
  margin: 0 0 60px 0;
  cursor: pointer;
  font-size: 14px;
  line-height: 16.94px;
  color: #3456F3;
}

@media screen and (max-width: 420px){
  .not-found__heading{
    font-size: 80px;
    font-weight: 400;
    line-height: 97px;
    max-width: 300px;
  }
  .not-found__subheading{
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    max-width: 300px;
  }
  .not-found__go-back{
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
  }
}
