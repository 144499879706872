.about-me{
  min-height: 625px;
  padding: 110px 70px 100px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 66px;
  width: 100%;
}
.about-me__description{
  display: grid;
  grid-template-columns: repeat(2, minmax(max-content, max-content));
  justify-content: space-between;
  align-items: flex-end;
  margin: 0;
}
.about-me__text-content{
  display: flex;
  flex-direction: column;
  gap: 18px;
}
.about-me__title{
  font-size: 50px;
  font-weight: 400;
  line-height: 58px;
  max-width: 600px;
  min-width: 292px;
  margin: 0;
}
.about-me__subtitle{
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  max-width: 600px;
  min-width: 292px;
  margin: 0;

}
.about-me__text{
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  max-width: 600px;
  min-width: 292px;
  margin: 0;
  padding: 8px 0 99px ;
}
.about-me__photo{
  max-height: 327px;
  max-width: 270px;
  border-radius: 10px;
  object-fit: contain;
}
.links{
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  gap: 20px;
}
.links__link{
  text-decoration: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  max-width: 66px;
  color: #000000;
}
@media screen and (max-width: 1000px){
  .about-me {
    padding: 90px 50px 90px;
  }
  .about-me__photo {
    max-height: 307px;
    max-width: 255px;
  }
  .about-me__text-content{
    max-width: 364px;
    gap: 16px;
  }
  .about-me__title{
    font-size: 40px;
    font-weight: 400;
    line-height: 40px;
  }
  .about-me__subtitle{
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
  }
  .about-me__text{
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    padding-top: 4px;
  }
}
@media screen and (max-width: 730px){
  .about-me {
    padding: 70px 14px 70px;
  }
  .about-me__photo {
    max-height: 307px;
    max-width: 255px;
  }
  .about-me__text-content{
    max-width: 292px;
  }
  .about-me__title{
    font-size: 30px;
    font-weight: 400;
    line-height: 36px;
  }
  .about-me__subtitle{
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
  }
  .about-me__text{
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
  }
}
@media screen and (max-width: 730px){
  .about-me__photo {
    max-height: 392px;
    max-width: 292px;
    width: 100%;
    height: 100%;
  }
  .about-me__description{
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 40px;
  }
  .links__link{
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
  }
}
@media screen and (max-width: 430px){
  .about-me__text-content{
    gap: 20px;
  }
  .about-me__text{
    padding: 0 0 20px 0;
  }
}
