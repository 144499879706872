@import "/src/vendor/fonts/fonts.css";
.page {
  margin: 0 auto;
  max-width: 1280px;
  min-width: 320px;
  font-family: 'Inter', sans-serif;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}
.hover-button{
  opacity: 1;
  transition: opacity .5s ease;
  cursor:pointer;
}
.hover-button:hover{
  opacity: .3;
  transition: opacity .5s ease;
}
.hover-link{
  opacity: 1;
  transition: opacity.5s ease;
  cursor:pointer;
}
.hover-link:hover{
  opacity: .2;
  transition: opacity .5s ease;
}
